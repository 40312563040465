<template>
  <Field
    v-bind:name="$attrs.id"
    v-slot="{ field }"
    v-bind:class="{
      [blockName]: true,
      [modifierClasses]: true,
    }"
    type="checkbox"
    v-bind:value="value"
  >
    <input
      v-bind="{ ...field, ...$attrs }"
      v-bind:name="$attrs.id"
      v-bind:value="value"
      type="checkbox"
      class="formCheckBox__input"
    />

    <label
      v-bind:for="$attrs.id"
      class="formCheckBox__label"
    >
      <font-awesome-icon
        v-bind:icon="['fas', 'check']"
        class="formCheckBox__check"
      />

      <span class="formCheckBox__text">
        {{ label }}
      </span>
    </label>
  </Field>
</template>

<script>
import { Field } from 'vee-validate';
import modifiers from '@/mixins/modifiers';

export default {
  inheritAttrs: false,

  mixins: [
    modifiers([
      'align-top',
    ]),
  ],

  components: {
    Field,
  },

  props: {
    label :{
      type: String,
      required: true,
    },

    value: {
      type: [String, Boolean],
      required: true,
    },
  },

  data() {
    return {
      blockName: 'formCheckBox',
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.formCheckBox {
  position: relative;
  margin: 0 0 rem(8px) 0;
}

.formCheckBox__label {
  @include label;
  display: inline-flex;
  align-items: center;
  position: relative;
  cursor: pointer;

  .formCheckBox--align-top & {
    align-items: flex-start;
  }

  &:before {
    content: "";
    margin: 0 rem(8px) 0 0;
    width: rem(16px);
    height: rem(16px);
    border-radius: rem(4px);
    border: rem(1px) solid $color-beige--dark;
    background: $color-white;
  }
}

.formCheckBox__check {
  display: none;
  position: absolute;
  top: rem(1px);
  left: rem(2px);
  font-size: rem(16px);
  color: $color-blue;
}

.formCheckBox__text {
  color: $color-text;

  .formCheckBox--align-top & {
    margin: rem(1px) 0 0 0;
  }
}

.formCheckBox__input {
  width: 0;
  opacity: 0;

  &:checked+.formCheckBox__label .formCheckBox__check {
    display: block;
  }
}
</style>