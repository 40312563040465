<template>
  <div class="forgotPasswordForm">
    <div class="forgotPasswordForm__title">
      {{ $t('forgetPasswordForm.title') }}
    </div>

    <div v-show="!passwordRequested">
      <p class="forgotPasswordForm__body">
        {{ $t('forgetPasswordForm.body') }}
      </p>

      <Form
        v-bind:validation-schema="validationSchema"
        v-slot="{ handleSubmit, isSubmitting }"
        as="div"
      >
        <base-form
          v-on:submit="handleSubmit($event, onSubmit)"
        >
          <base-form-element>
            <base-form-label for-id="email">
              {{ $t('form.emailLabel') }}
            </base-form-label>

            <base-form-input
              type="email"
              id="email"
            />

            <base-form-element-error name="email" />
          </base-form-element>

          <base-form-element>
            <base-button
              modifiers="tertiary block fullWidth"
              v-bind:show-spinner="isSubmitting"
            >
              {{ $t('forgetPasswordForm.sendButtonLabel') }}
            </base-button>
          </base-form-element>
        </base-form>
      </Form>

      <router-link
        v-bind:to="{ name: 'login' }"
        class="forgotPasswordForm__backLink"
      >
        {{ $t('forgetPasswordForm.backToLoginLinkLabel') }}
      </router-link>
    </div>

    <div v-if="passwordRequested">
      <p class="forgotPasswordForm__requestSentBody">
        {{ $t('forgetPasswordForm.requestSentBody') }}
      </p>

      <base-button
        v-bind:to="{ name: 'login' }"
        modifiers="secondary block fullWidth"
      >
        {{ $t('forgetPasswordForm.backButtonLabel') }}
      </base-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { Form } from 'vee-validate';
import * as yup from 'yup';
import BaseForm from '@/components/forms/BaseForm';
import BaseFormError from '@/components/forms/BaseFormError';
import BaseFormElement from '@/components/forms/BaseFormElement';
import BaseFormElementError from '@/components/forms/BaseFormElementError';
import BaseFormLabel from '@/components/forms/BaseFormLabel';
import BaseFormInput from '@/components/forms/BaseFormInput';
import BaseButton from '@/components/BaseButton';
import { i18n } from '@/plugins/i18n';

export default {
  components: {
    Form,
    BaseForm,
    BaseFormError,
    BaseFormElement,
    BaseFormElementError,
    BaseFormLabel,
    BaseFormInput,
    BaseButton,
  },

  data() {
    return {
      passwordRequested: false,
      validationSchema: yup.object({
        email: yup
          .string()
          .email(() => i18n.global.t('form.errorEmail'))
          .required(() => i18n.global.t('form.errorRequired')),
      }),
    };
  },

  computed: {
    ...mapState('auth', ['token']),
  },

  mounted() {
    if (this.token) {
      this.$router.push({ name: 'index' });
    }
  },

  methods: {
    ...mapActions('auth', ['forgotPassword']),

    async onSubmit(values) {
      const { email } = values;

      try {
        await this.forgotPassword({ email });
      } catch(error) {
        //
      } finally {
        this.passwordRequested = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.forgotPasswordForm__title {
  @include heading-4;
  margin: 0 0 1rem 0;
  text-align: center;
}

.forgotPasswordForm__body,
.forgotPasswordForm__requestSentBody {
  @include small;
  margin: 0 0 2rem 0;
  color: rgba($color-text, 0.7);
}

.forgotPasswordForm__backLink {
  @include small;
  display: block;
  margin: rem(20px) 0 0 0;
  text-decoration: underline;
  text-align: center;
  color: rgba($color-text, 0.7);
}
</style>