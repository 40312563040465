<template>
  <div class="passwordResetForm">
    <div class="passwordResetForm__title">
      {{ $t('resetPasswordForm.title') }}
    </div>

    <p class="passwordResetForm__body">
      {{ $t('resetPasswordForm.body') }}
    </p>

    <Form
      v-bind:validation-schema="validationSchema"
      v-slot="{ handleSubmit, isSubmitting }"
      as="div"
    >
      <base-form v-on:submit="handleSubmit($event, onSubmit)">
        <base-form-element>
          <base-form-label for-id="email">
            {{ $t('form.emailLabel') }}
          </base-form-label>

          <base-form-input
            type="email"
            id="email"
          />

          <base-form-element-error name="email" />
        </base-form-element>

        <base-form-element>
          <base-form-label for-id="password">
            {{ $t('form.passwordLabel') }}
          </base-form-label>

          <base-form-input
            type="password"
            id="password"
          />

          <base-form-element-note>
            {{ $t('form.passwordFieldNote') }}
          </base-form-element-note>

          <base-form-element-error name="password" />
        </base-form-element>

        <base-form-element>
          <base-form-label for-id="password">
            {{ $t('form.repeatPasswordLabel') }}
          </base-form-label>

          <base-form-input
            type="password"
            id="passwordConfirmation"
          />

          <base-form-element-error name="passwordConfirmation" />
        </base-form-element>

        <base-form-error
          v-if="errorKey"
        >
          <i18n-t
            v-if="errorKey === 'passwords.token'"
            keypath="resetPasswordForm.errorToken"
            tag="span"
          >
            <template v-slot:action>
              <router-link
                v-bind:to="{ name: 'forgotPassword' }"
                class="passwordResetForm_formErrorLink"
              >
                {{ $t('common.clickHere') }}
              </router-link>
            </template>
          </i18n-t>

          <span v-else-if="errorKey === 'passwords.user'">
            {{ $t('resetPasswordForm.erroruser') }}
          </span>

          <span v-else>
            {{ $t('common.oopsTryAgain') }}
          </span>
        </base-form-error>

        <base-form-element>
          <base-button
            modifiers="tertiary block fullWidth"
            v-bind:show-spinner="isSubmitting"
            v-bind:disabled="isSubmitting"
          >
            {{ $t('resetPasswordForm.changePasswordButtonLabel') }}
          </base-button>
        </base-form-element>
      </base-form>
    </Form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { Form } from 'vee-validate';
import * as yup from 'yup';
import BaseForm from '@/components/forms/BaseForm';
import BaseFormError from '@/components/forms/BaseFormError';
import BaseFormElement from '@/components/forms/BaseFormElement';
import BaseFormElementNote from '@/components/forms/BaseFormElementNote';
import BaseFormElementError from '@/components/forms/BaseFormElementError';
import BaseFormLabel from '@/components/forms/BaseFormLabel';
import BaseFormInput from '@/components/forms/BaseFormInput';
import BaseButton from '@/components/BaseButton';
import { i18n } from '@/plugins/i18n';

export default {
  components: {
    Form,
    BaseForm,
    BaseFormError,
    BaseFormElement,
    BaseFormElementNote,
    BaseFormElementError,
    BaseFormLabel,
    BaseFormInput,
    BaseButton,
  },

  props: {
    resetToken: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      errorKey: '',
      validationSchema: yup.object({
        email: yup
          .string()
          .email(() => i18n.global.t('form.errorEmail'))
          .required(() => i18n.global.t('form.errorRequired')),
        password: yup
          .string()
          .min(6, () => i18n.global.t('form.errorPasswordLength'))
          .required(() => i18n.global.t('form.errorRequired')),
        passwordConfirmation: yup
          .string()
          .oneOf([yup.ref('password'), null], () => i18n.global.t('form.errorPasswordMatch'))
          .required(() => i18n.global.t('form.errorRequired')),
      }),
    };
  },

  methods: {
    ...mapActions('auth', ['resetPassword']),

    async onSubmit(values) {
      const postData = {
        ...values,
        token: this.resetToken,
      };

      try {
        await this.resetPassword(postData);
        this.$emit('passwordReset', postData);
      } catch (error) {
        this.errorKey = error.response.data.response;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.passwordResetForm__title {
  @include heading-4;
  margin: 0 0 1rem 0;
  text-align: center;
}

.passwordResetForm__body {
  @include small;
  margin: 0 0 2rem 0;
  text-align: center;
  color: rgba($color-text, 0.7);
}

.passwordResetForm_formErrorLink {
  text-decoration: underline;
}
</style>