<template>
  <div class="loginForm">
    <div class="loginForm__title">
      {{ $t('loginForm.title') }}
    </div>

    <i18n-t
      keypath="loginForm.body"
      tag="p"
      class="loginForm__body"
    >
      <template v-slot:action>
        <a
          href="/prijzen"
          class="loginForm__bodyLink"
        >
          {{ $t('loginForm.bodyLinkLabel') }}
        </a>
      </template>
    </i18n-t>

    <Form
      v-bind:validation-schema="validationSchema"
      v-slot="{ handleSubmit, isSubmitting }"
      as="div"
    >
      <base-form v-on:submit="handleSubmit($event, onSubmit)">
        <base-form-element>
          <base-form-label for-id="email">
            {{ $t('form.emailLabel') }}
          </base-form-label>

          <base-form-input
            type="email"
            id="email"
            tabindex="1"
          />

          <base-form-element-error name="email" />
        </base-form-element>

        <base-form-element>
          <base-form-label
            for-id="password"
            modifiers="block"
          >
            <div class="loginForm__passwordLabel">
              {{ $t('form.passwordLabel') }}

              <router-link
                v-bind:to="{ name: 'forgotPassword' }"
                class="loginForm__forgotPasswordLink"
                tabindex="4"
              >
                {{ $t('loginForm.forgotPasswordLinkLabel') }}
              </router-link>
            </div>
          </base-form-label>

          <base-form-input
            type="password"
            id="password"
            tabindex="2"
          />

          <base-form-element-error name="password" />
        </base-form-element>

        <base-form-element>
          <base-form-check-box
            id="rememberMe"
            v-bind:label="$t('loginForm.rememberMeLabel')"
            v-bind:value="rememberMe"
            v-bind:checked="rememberMe"
            v-on:input="rememberMe = $event.target.checked"
            tabindex="3"
          />
        </base-form-element>

        <base-form-error v-if="hasError">
          {{ $t('loginForm.errorCredentials') }}
        </base-form-error>

        <base-form-element>
          <base-button
            modifiers="tertiary block fullWidth"
            v-bind:show-spinner="isSubmitting"
            loading-text="Authenticeren"
            tabindex="5"
          >
            {{ $t('loginForm.loginButtonLabel') }}
          </base-button>
        </base-form-element>
      </base-form>
    </Form>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { Form } from 'vee-validate';
import * as yup from 'yup';
import BaseForm from '@/components/forms/BaseForm';
import BaseFormElementError from '@/components/forms/BaseFormElementError';
import BaseFormError from '@/components/forms/BaseFormError';
import BaseFormElement from '@/components/forms/BaseFormElement';
import BaseFormLabel from '@/components/forms/BaseFormLabel';
import BaseFormInput from '@/components/forms/BaseFormInput';
import BaseFormCheckBox from '@/components/forms/BaseFormCheckBox';
import BaseButton from '@/components/BaseButton';
import { i18n } from '@/plugins/i18n';

export default {
  components: {
    Form,
    BaseForm,
    BaseFormElementError,
    BaseFormError,
    BaseFormElement,
    BaseFormLabel,
    BaseFormInput,
    BaseFormCheckBox,
    BaseButton,
  },

  data() {
    return {
      rememberMe: true,
      hasError: false,
      validationSchema: yup.object({
        email: yup
          .string()
          .email(() => i18n.global.t('form.errorEmail'))
          .required(() => i18n.global.t('form.errorRequired')),
        password: yup
          .string()
          .required(() => i18n.global.t('form.errorRequired')),
      }),
    };
  },

  computed: {
    ...mapState('auth', ['token']),
  },

  mounted() {
    if (this.token) {
      this.$router.push({ name: 'index' });
    }
  },

  beforeUnmount() {
    clearTimeout(this.imageTimer);
  },

  methods: {
    ...mapActions({
      loginUser: 'auth/login',
    }),

    async onSubmit(values) {
      this.hasError = false;

      const postData = { ...values };
      postData.rememberMe = this.rememberMe;

      try {
        await this.loginUser(postData);
        this.onLoginSuccess();
      } catch (error) {
        this.hasError = true;
      }
    },

    onLoginSuccess() {
      const landingUrl = sessionStorage.getItem('landingUrl');

      if (!landingUrl) {
        this.$router.push({ name: 'index' });
        return;
      }

      const route = JSON.parse(landingUrl);

      this.$router.push(route)
        .then(() => sessionStorage.removeItem('landingUrl'));
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.loginForm__title {
  @include heading-4;
  margin: 0 0 1rem 0;
  text-align: center;
}

.loginForm__body {
  @include small;
  margin: 0 0 2rem 0;
  text-align: center;
  color: rgba($color-text, 0.7);
}

.loginForm__bodyLink {
  text-decoration: underline;
  color: $color-orange;
}

.loginForm__passwordLabel {
  display: flex;
  justify-content: space-between;
}

.loginForm__forgotPasswordLink {
  @include note;
  display: block;
  text-align: center;
  text-decoration: underline;
  color: rgba($color-text, 0.7);
}
</style>
